import { LockIcon } from 'lucide-react';
import { useRef, type ReactNode } from 'react';
import { Button } from './ui/button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from './ui/hover-card';
import { UpgradeModal } from './upgrade-modal';

export function UpgradeTooltip({
  children,
  showTryIt
}: {
  children: ReactNode;
  showTryIt?: boolean;
}) {
  const triggerRef = useRef<HTMLAnchorElement>(null);

  return (
    <HoverCard openDelay={10}>
      <HoverCardTrigger ref={triggerRef} asChild>
        {children}
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        <div className="flex flex-row space-x-2">
          <LockIcon className="col-span-2 h-5 w-5 flex-shrink-0 text-primary" />
          <div className="text-sm leading-relaxed">
            This feature is part of the Growth plan, feel free to try it out for a limited
            time or upgrade to unlock.
            <div className="mt-2 flex flex-row space-x-2">
              <UpgradeModal defaultValue="forTeam">
                <Button size="sm">Upgrade now</Button>
              </UpgradeModal>

              {showTryIt && (
                <Button
                  variant="link"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    triggerRef.current?.click();
                  }}>
                  Try it
                </Button>
              )}
            </div>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
