import { Link } from '@remix-run/react';
import { motion } from 'framer-motion';
import { Check, CircleHelp, Minus, Plus, XIcon } from 'lucide-react';
import type { ReactNode, SVGProps } from 'react';
import { Fragment, useState } from 'react';
import type { getPrices } from '~/models/price/get-price';
import { type DataSourcesPrices } from '~/services/stripe/config.server';
import { cn } from '~/utils/cn';
import { featuresFor, freePlanAttrs, type Feature } from '~/utils/price-table';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from './ui/accordion';
import { Button } from './ui/button';
import { Label } from './ui/label';
import { RadioGroup, RadioGroupItem } from './ui/radio-group';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';

const intervals = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/ month' },
  { value: 'yearly', label: 'Annually', priceSuffix: '/ year' }
];

const accordionContentClasses =
  'grid w-full grid-cols-1 gap-4 rounded-b-lg border-x-2 border-b-2 border-black bg-teal-100 px-3 pt-4 lg:grid-cols-3';

const ENTERPRISE_CONTACT_URL = 'https://tally.so/r/w52XBo';

export function PriceTable({
  defaultValue,
  onValueChange,
  planKeyname,
  planInterval,
  embedPlanKeyname,
  embedPlanInterval,
  prices
}: {
  defaultValue?: 'forTeam' | 'forSite';
  onValueChange?: (value: string) => void;
  planKeyname?: string;
  planInterval?: string;
  embedPlanKeyname?: string;
  embedPlanInterval?: string;
  prices: Awaited<ReturnType<typeof getPrices>>;
}) {
  const [interval, setInterval] = useState(
    intervals.find((f) => f.value === planInterval) ||
      intervals.find((f) => f.value === embedPlanInterval) ||
      intervals[0]
  );
  const intervalValue = interval.value as 'monthly' | 'yearly';
  const freeDataSourcesPrices = Object.entries(prices.standardUsersDataSources).reduce(
    (acc, [key, value]) => {
      acc[key as 'monthly' | 'yearly'] = {
        ...value,
        firstTierUpTo: freePlanAttrs.dataSourceLimit,
        firstTierUpToFormatted: new Intl.NumberFormat('en-US').format(
          freePlanAttrs.dataSourceLimit
        )
      };
      return acc;
    },
    {} as DataSourcesPrices
  );

  return (
    <>
      {(!planInterval || planKeyname === 'free') && !embedPlanInterval ? (
        <div className="mb-8 mt-16 flex justify-center">
          <RadioGroup
            value={intervalValue}
            onValueChange={(newValue) =>
              setInterval(intervals.find((f) => f.value === newValue) || intervals[0])
            }
            className="flex flex-row rounded-full border-2 border-black px-1 py-1 text-center text-xs font-semibold leading-5 shadow-[5px_5px_0px_0px_#000,4px_4px_0px_0px_#000,3px_3px_0px_0px_#000,2px_2px_0px_0px_#000,1px_1px_0px_0px_#000]">
            {intervals.map((option) => (
              <Fragment key={option.value}>
                <RadioGroupItem
                  id={option.value}
                  value={option.value}
                  className="hidden"
                />

                <Label
                  htmlFor={option.value}
                  className={cn(
                    interval.value === option.value
                      ? 'bg-primary text-primary-foreground'
                      : 'text-gray-500 dark:text-gray-400',
                    "cursor-pointer rounded-full px-2 py-1.5 font-['Press_Start_2P'] text-xs"
                  )}>
                  {option.label}
                </Label>
              </Fragment>
            ))}
          </RadioGroup>
        </div>
      ) : null}

      <TooltipProvider>
        <Accordion
          type="single"
          collapsible
          defaultValue={defaultValue}
          onValueChange={onValueChange}
          className="w-full space-y-4">
          <AccordionItem value="forTeam" className="w-full border-0">
            <AccordionTrigger className="w-full justify-start rounded-lg border-2 border-black text-lg tracking-wide hover:no-underline [&[data-state=open]>svg.minus]:block [&[data-state=open]>svg.plus]:hidden [&[data-state=open]]:rounded-b-none [&_svg.minus]:hidden [&_svg.shrink-0]:hidden">
              <Plus className="plus ml-2" />
              <Minus className="minus ml-2" />
              <div className="ml-2 border-black pl-3 font-['Raleway'] font-extrabold md:border-l-2">
                For Your Team
              </div>
              <div className="ml-auto items-end pr-4 font-['Raleway'] font-bold">
                {prices.standardUsers[intervalValue].amountFormatted} / user{' '}
                {interval.priceSuffix}
              </div>
            </AccordionTrigger>
            <AccordionContent className={accordionContentClasses}>
              <RoundyFunBox className="space-y-2">
                <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                  <RoundyFunBoxTitle>Free</RoundyFunBoxTitle>

                  {!planKeyname && (
                    <ActionButton href="/register" variant="dark">
                      Get Started
                    </ActionButton>
                  )}

                  {planKeyname === 'free' && <CurrentPlanButton />}
                </div>
                <DataSources
                  dataSourcesPrices={freeDataSourcesPrices}
                  interval={interval}
                />
                <div className="mt-4 flex items-center space-x-2 text-sm font-normal text-gray-800 dark:text-gray-300">
                  <div>1 Team Member</div>
                  <RoundyFunTooltip>
                    Upgrade to add additional team members and aggregate usage across your
                    team.
                  </RoundyFunTooltip>
                </div>
                <div className="mt-4 flex items-center space-x-2 text-sm font-normal text-gray-800 dark:text-gray-300">
                  <div>{freePlanAttrs.messageLimit} messages / month</div>
                  <RoundyFunTooltip>
                    Upgrade to send more messages and unlock additional features.
                  </RoundyFunTooltip>
                </div>
                {featuresFor('forTeam', 'Free').map((feature) => (
                  <FeatureItem key={feature.name} feature={feature} />
                ))}
              </RoundyFunBox>
              <RoundyFunBox className="space-y-2">
                <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                  <RoundyFunBoxTitle>Growth</RoundyFunBoxTitle>

                  {!planKeyname && (
                    <ActionButton href="/register" variant="dark">
                      Get Started
                    </ActionButton>
                  )}

                  {planKeyname &&
                    planKeyname !== 'free' &&
                    planKeyname !== 'enterprise' && <CurrentPlanButton />}
                  {planKeyname === 'free' && (
                    <ActionButton href="/checkout/standardUsers" variant="dark">
                      Upgrade
                    </ActionButton>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <h3 className="font-['Raleway'] text-lg font-extrabold">
                    {prices.standardUsers[intervalValue].amountFormatted} / user{' '}
                    {interval.priceSuffix}
                  </h3>
                  <RoundyFunTooltip>
                    A user is anyone who interacts with tia, e.g. sending a message to tia
                    in Slack.
                  </RoundyFunTooltip>
                </div>

                <DataSources
                  dataSourcesPrices={prices.standardUsersDataSources}
                  interval={interval}
                />

                <FeatureItem
                  feature={{
                    name: 'Everything in the Free plan',
                    tiers: { Growth: true },
                    appliesTo: ['forTeam']
                  }}
                />
                <FeatureItem
                  feature={{
                    name: 'Unlimited Messages',
                    tiers: { Growth: true },
                    appliesTo: ['forTeam']
                  }}
                />
                <FeatureItem
                  feature={{
                    name: 'Unlimited Team Members',
                    tiers: { Growth: true },
                    appliesTo: ['forTeam']
                  }}
                />

                {featuresFor('forTeam', 'Growth').map((feature) => (
                  <FeatureItem key={feature.name} feature={feature} />
                ))}
              </RoundyFunBox>
              <RoundyFunBox className="space-y-2">
                <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                  <RoundyFunBoxTitle>Enterprise</RoundyFunBoxTitle>

                  {planKeyname === 'enterprise' && <CurrentPlanButton />}
                  {planKeyname !== 'enterprise' && (
                    <ActionButton href={ENTERPRISE_CONTACT_URL}>Contact Us</ActionButton>
                  )}
                </div>
                <FeatureItem
                  feature={{
                    name: 'Everything in the Growth plan',
                    tiers: { Enterprise: true },
                    appliesTo: ['forTeam']
                  }}
                />
                {featuresFor('forTeam', 'Enterprise').map((feature) => (
                  <FeatureItem key={feature.name} feature={feature} />
                ))}
              </RoundyFunBox>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="forSite" className="w-full border-0">
            <AccordionTrigger className="w-full justify-start rounded-lg border-2 border-black text-lg tracking-wide hover:no-underline [&[data-state=open]>svg.minus]:block [&[data-state=open]>svg.plus]:hidden [&[data-state=open]]:rounded-b-none [&_svg.minus]:hidden [&_svg.shrink-0]:hidden">
              <Plus className="plus ml-2 flex-shrink-0" />
              <Minus className="minus ml-2 flex-shrink-0" />
              <div className="ml-2 border-black pl-3 font-['Raleway'] font-extrabold md:border-l-2">
                For Your Site, Product, or Documentation
              </div>
              <div className="ml-auto items-end pr-4 font-['Raleway'] font-bold">
                {prices.embedCredits[intervalValue].firstTierAmountFormatted}{' '}
                {interval.priceSuffix}
              </div>
            </AccordionTrigger>
            <AccordionContent className={accordionContentClasses}>
              <RoundyFunBox>
                <RoundyFunBoxTitle>Free</RoundyFunBoxTitle>
                <div className="-ml-1 mt-4 flex items-center">
                  <XIcon className="h-6 w-6 text-red-500" />
                  Not available
                </div>
              </RoundyFunBox>
              <RoundyFunBox className="space-y-2">
                <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                  <RoundyFunBoxTitle>Growth</RoundyFunBoxTitle>

                  {!planKeyname && (
                    <ActionButton href="/register" variant="dark">
                      Get Started
                    </ActionButton>
                  )}

                  {embedPlanKeyname && embedPlanKeyname !== 'enterprise' && (
                    <CurrentPlanButton />
                  )}
                  {!embedPlanKeyname && planKeyname && planKeyname !== 'free' && (
                    <ActionButton href="/subscription-change/embedCredits" variant="dark">
                      Upgrade
                    </ActionButton>
                  )}
                  {!embedPlanKeyname && planKeyname && planKeyname === 'free' && (
                    <ActionButton href="/checkout/embedCredits" variant="dark">
                      Upgrade
                    </ActionButton>
                  )}
                </div>

                <h3 className="font-['Raleway'] text-lg font-extrabold">
                  {prices.embedCredits[intervalValue].firstTierAmountFormatted}{' '}
                  {interval.priceSuffix}
                </h3>
                <div className="mt-4 flex items-center space-x-2 text-sm font-normal text-gray-800 dark:text-gray-300">
                  <div>
                    {prices.embedCredits[intervalValue].firstTierUpToFormatted} Message
                    Credits {interval.priceSuffix}
                  </div>
                  <RoundyFunTooltip>
                    Each message sent by an end-user consumes a certain number of message
                    credits depending on the AI model you choose. For the standard AI
                    model, each message sent by an end-user consumes 1 message credit. For
                    more advanced AI models, each message consumes 2 message credits.
                    {prices.embedCredits[intervalValue].firstTierUpTo &&
                    prices.embedCredits[intervalValue].nextTierUpTo ? (
                      <h4 className="mt-4">
                        <strong>Additional credits:</strong>
                        <br />
                        {prices.embedCredits[intervalValue].nextTierUpTo -
                          prices.embedCredits[intervalValue].firstTierUpTo}{' '}
                        credits for{' '}
                        {prices.embedCredits[intervalValue].nextTierAmountFormatted}{' '}
                        {interval.priceSuffix}
                      </h4>
                    ) : prices.embedCredits[intervalValue].nextTierAmountFormatted ? (
                      <h4 className="mt-4">
                        <strong>Additional credits:</strong>
                        <br />
                        {prices.embedCredits[intervalValue].nextTierAmountFormatted} /
                        credit
                      </h4>
                    ) : null}
                  </RoundyFunTooltip>
                </div>

                <DataSources
                  dataSourcesPrices={prices.embedCreditsDataSources}
                  interval={interval}
                />

                {featuresFor('forSite', 'Free').map((feature) => (
                  <FeatureItem key={feature.name} feature={feature} />
                ))}
                {featuresFor('forSite', 'Growth').map((feature) => (
                  <FeatureItem key={feature.name} feature={feature} />
                ))}
              </RoundyFunBox>
              <RoundyFunBox className="space-y-2">
                <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                  <RoundyFunBoxTitle>Enterprise</RoundyFunBoxTitle>

                  {embedPlanKeyname === 'enterprise' && <CurrentPlanButton />}
                  {embedPlanKeyname !== 'enterprise' && (
                    <ActionButton href={ENTERPRISE_CONTACT_URL}>Contact Us</ActionButton>
                  )}
                </div>

                <FeatureItem
                  feature={{
                    name: 'Everything in the Growth plan',
                    tiers: { Enterprise: true },
                    appliesTo: ['forSite']
                  }}
                />
                {featuresFor('forSite', 'Enterprise').map((feature) => (
                  <FeatureItem key={feature.name} feature={feature} />
                ))}
              </RoundyFunBox>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </TooltipProvider>
    </>
  );
}

function CurrentPlanButton() {
  return (
    <div className="py-2 font-['Press_Start_2P'] text-gray-600 dark:text-gray-400">
      Current Plan
    </div>
  );
}

function ActionButton({
  children,
  variant = 'light',
  href
}: {
  children: ReactNode;
  href: string;
  variant?: 'light' | 'dark';
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Button
      variant="link"
      asChild
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Link
        to={href}
        className={cn(
          "relative mt-4 rounded-lg border-2 border-black px-5 py-5 font-['Press_Start_2P'] text-xs uppercase tracking-wide sm:mt-0",
          variant === 'dark' ? 'bg-primary text-white' : 'bg-white text-black'
        )}>
        {children}
        <motion.div
          className="absolute -right-4 -top-5 w-6"
          animate={{ rotate: isHovered ? 360 : 0 }}
          transition={{ duration: 0.5 }}>
          <Zam />
        </motion.div>
      </Link>
    </Button>
  );
}

const Zam = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="none" {...props}>
    <path
      fill="#3E3AF2"
      stroke="#3E3AF2"
      strokeWidth={2.928}
      d="M35.755 24.425 30.59 1 26.3 24.425 1 31.911l25.3 4.051 5.779 23.601 3.676-23.601 23.2-7.517-23.2-4.02Z"
    />
  </svg>
);

function DataSources({
  dataSourcesPrices,
  interval
}: {
  dataSourcesPrices: DataSourcesPrices;
  interval: {
    value: string;
    priceSuffix: string;
  };
}) {
  const intervalValue = interval.value as 'monthly' | 'yearly';
  const dataSourcePrice = dataSourcesPrices[intervalValue];

  return (
    <div className="mt-4 flex items-center space-x-2 text-sm font-normal text-gray-800 dark:text-gray-300">
      <div>{dataSourcePrice.firstTierUpTo} Data Sources</div>
      <RoundyFunTooltip>
        A data source is a collection of URLs or documents from which tia ingests and
        indexes content, such as support documentation, help articles, an uploaded PDF, or
        academy courses.
        <h4 className="mt-4">
          <strong>Additional data sources:</strong>
          <br />
          {dataSourcePrice.nextTierAmountFormatted} / data source {interval.priceSuffix}
        </h4>
      </RoundyFunTooltip>
    </div>
  );
}

function FeatureItem({ feature }: { feature: Feature }) {
  return (
    <div className="flex min-h-8 items-center space-x-2">
      <Check className="h-4 w-4" />
      <div>{feature.name}</div>
      {feature.description ? (
        <RoundyFunTooltip>{feature.description}</RoundyFunTooltip>
      ) : null}
    </div>
  );
}

function RoundyFunTooltip({ children }: { children: React.ReactNode }) {
  return (
    <Tooltip delayDuration={10}>
      <TooltipTrigger asChild>
        <Button variant="link" size="sm" className="p-0">
          <CircleHelp className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="mr-10 max-w-xs border-2 border-black bg-white text-sm text-black shadow-[5px_5px_0px_0px_#000,4px_4px_0px_0px_#000,3px_3px_0px_0px_#000,2px_2px_0px_0px_#000,1px_1px_0px_0px_#000] dark:bg-gray-800 dark:text-gray-200">
        {children}
      </TooltipContent>
    </Tooltip>
  );
}

function RoundyFunBox({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'w-full rounded-lg border-2 border-black bg-white px-4 py-5 shadow-[5px_5px_0px_0px_#000,4px_4px_0px_0px_#000,3px_3px_0px_0px_#000,2px_2px_0px_0px_#000,1px_1px_0px_0px_#000] dark:bg-gray-800 dark:text-gray-200 lg:grid-cols-2',
        className
      )}>
      {children}
    </div>
  );
}

function RoundyFunBoxTitle({ children }: { children: ReactNode }) {
  return <div className="font-['Raleway'] text-2xl font-extrabold">{children}</div>;
}
