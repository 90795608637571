import { useFetcher } from '@remix-run/react';
import { useEffect, type ReactNode } from 'react';
import { SmallLoader } from './loader';
import { PriceTable } from './price-table';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from './ui/sheet';

export function UpgradeModal({
  children,
  defaultValue
}: {
  children: ReactNode;
  defaultValue?: 'forTeam' | 'forSite';
}) {
  const fetcher = useFetcher<Parameters<typeof PriceTable>[0]>();

  useEffect(() => {
    fetcher.load('/api/prices');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="flex w-full !max-w-[unset] flex-col gap-2.5 overflow-auto lg:w-[calc(83.333333%-60px)]">
        <SheetHeader>
          <SheetTitle>Upgrade to access</SheetTitle>
          <SheetDescription>
            Unlock more features by upgrading your account
          </SheetDescription>
        </SheetHeader>

        {fetcher.data ? (
          <PriceTable {...fetcher.data} defaultValue={defaultValue} />
        ) : (
          <SmallLoader />
        )}
      </SheetContent>
    </Sheet>
  );
}
