export type Section = {
  name: string;
  features: Feature[];
};
export type AppliesTo = 'forTeam' | 'forSite';
export type Feature = {
  name: string;
  description?: string;
  tiers: {
    Free?: boolean | string;
    Growth?: boolean | string;
    Enterprise?: boolean | string;
  };
  appliesTo: AppliesTo[];
};

export const freePlanAttrs = {
  planInterval: 'monthly',
  planKeyname: 'free',
  messageLimit: 25,
  dataSourceLimit: 2
};

export const priceTable: Section[] = [
  {
    name: 'Features',
    features: [
      {
        name: 'AI-Powered Answers',
        description:
          'Provides direct, generative AI-powered responses for quick information retrieval.',
        tiers: { Free: true, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'Customizable Experiences',
        description:
          'Create tailored AI interactions by combining specific data sources with preferred communication channels. Configure unique experiences, such as API documentation in Slack, for targeted knowledge delivery across various platforms.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'Knowledge Base Integration',
        description:
          'Indexes and serves relevant content from your existing knowledge base.',
        tiers: { Free: true, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'Academy Content Integration',
        description:
          'Incorporates and recommends courses and learning paths from your academy.',
        tiers: { Free: true, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'Slack Integration',
        description: 'Allows teams to access tia directly within Slack.',
        tiers: { Free: true, Growth: true, Enterprise: true },
        appliesTo: ['forTeam']
      },
      {
        name: 'Customizable Personalization',
        description:
          'Adjust the AI\'s "temperature" and persona to match your brand guidelines and style.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'Model Selection',
        description:
          'Choose from a selection of AI models to optimize for speed, accuracy, or a balance of both.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forSite']
      },
      {
        name: 'Model Selection',
        description:
          'Choose from a selection of AI models to optimize for speed, accuracy, or a balance of both.',
        tiers: { Free: false, Growth: false, Enterprise: true },
        appliesTo: ['forTeam']
      },
      // {
      //   name: 'Team members',
      //   description:
      //     'Any user who interacts with tia (e.g., asks questions, accesses resources)',
      //   tiers: {
      //     Free: '1 User',
      //     Growth: true,
      //     Enterprise: true
      //   },
      //   appliesTo: []
      // },
      {
        name: 'Document Management',
        description:
          'Hide, show, and adjust the frequency of document appearances in search results and Q&A for optimal content control.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'Canned Responses',
        description:
          'Manually improve AI-generated responses for better accuracy and relevance.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'Daily Automatic Re-indexing',
        description:
          'Keeps your knowledge fresh with daily automatic updates. Every 24 hours, tia re-indexes all data sources, ensuring the latest information is always available for accurate responses and up-to-date recommendations.',
        tiers: { Free: false, Growth: false, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      }
      // {
      //   name: 'Product Embedding',
      //   description: 'Option to embed tia into your product or documentation site.',
      //   tiers: { Free: false, Growth: true, Enterprise: true },
      //   appliesTo: []
      // },
      // {
      //   name: 'Message Limit',
      //   description: 'The number of messages a user can send to tia in a month.',
      //   tiers: {
      //     Free: '25 messages / month',
      //     Growth: 'Unlimited messages',
      //     Enterprise: 'Unlimited messages'
      //   },
      //   appliesTo: []
      // }
    ]
  },
  {
    name: 'Reporting',
    features: [
      {
        name: 'Analytics and Usage Tracking',
        description: 'Provides insights on user interactions and content gaps.',
        tiers: { Free: true, Growth: true, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      },
      {
        name: 'User Friction Point Analysis',
        description:
          'Identifies areas where users commonly seek help or face challenges.',
        tiers: { Growth: false, Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      }
    ]
  },
  {
    name: 'Embedded in your docs or product',
    features: [
      // {
      //   name: 'Embed Message Credits',
      //   description:
      //     'We charge 1 message credit for each message sent by an end-user using the standard AI model. For the more advanced AI models, we charge 2 message credits per message.',
      //   tiers: {
      //     Free: false,
      //     Growth: '$999.00 for 5,000 message credits, every additional 1,000: +$250/mo',
      //     Enterprise: 'Custom pricing'
      //   },
      //   appliesTo: []
      // },
      {
        name: 'Context-Aware Predictive Search',
        description:
          "Offers predictive categories and questions based on user's location in the platform.",
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forSite']
      },
      {
        name: 'In-App Knowledge Articles',
        description:
          'Displays relevant articles without users leaving their current context.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forSite']
      },
      {
        name: 'Support Ticket Reduction',
        description: 'Alleviates strain on support teams by handling common inquiries.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forSite']
      },
      {
        name: 'Upsell Opportunity Identification',
        description: 'Promotes relevant courses to drive engagement and revenue.',
        tiers: { Free: false, Growth: true, Enterprise: true },
        appliesTo: ['forSite']
      }
    ]
  },
  {
    name: 'Security',
    features: [
      {
        name: 'Service Level Agreement (SLA)',
        description: 'Custom Service Level Agreement (SLA) to meet your business goals.',
        tiers: { Enterprise: true },
        appliesTo: ['forTeam', 'forSite']
      }
      // {
      //   name: 'GDPR Compliance',
      //   description: 'Compliance with the General Data Protection Regulation (GDPR)',
      //   tiers: { Free: true, Growth: true, Enterprise: true },
      //   appliesTo: ['forTeam', 'forSite']
      // }
    ]
  }
];

export function sectionsFor(
  appliesTo: 'forTeam' | 'forSite',
  tier: 'Free' | 'Growth' | 'Enterprise'
) {
  return priceTable.reduce((acc, section) => {
    const features = section.features.filter(
      (feature) =>
        feature.appliesTo.includes(appliesTo) &&
        (tier === 'Free'
          ? feature.tiers[tier]
          : tier === 'Growth'
            ? feature.tiers[tier] && !feature.tiers.Free
            : feature.tiers[tier] && !feature.tiers.Free && !feature.tiers.Growth)
    );
    if (features.length) {
      acc.push({ ...section, features });
    }
    return acc;
  }, [] as Section[]);
}

export function featuresFor(
  appliesTo: 'forTeam' | 'forSite',
  tier: 'Free' | 'Growth' | 'Enterprise'
) {
  return sectionsFor(appliesTo, tier).reduce(
    (acc, section) => acc.concat(section.features),
    [] as Feature[]
  );
}
