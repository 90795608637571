import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { LockIcon } from 'lucide-react';
import * as React from 'react';
import { cn } from '~/utils/cn';
import { UpgradeTooltip } from '../upgrade-tooltip';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn('border-b', className)} {...props} />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    locked?: boolean;
    upgradeTooltip?: boolean;
  }
>(({ className, children, locked = false, upgradeTooltip = false, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        !locked && '[&[data-state=open]>svg]:rotate-180',
        'flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:underline',
        className
      )}
      {...props}>
      {children}
      {locked ? (
        upgradeTooltip ? (
          <UpgradeTooltip>
            <LockIcon className="h-4 w-4 shrink-0 text-primary" />
          </UpgradeTooltip>
        ) : (
          <LockIcon className="h-4 w-4 shrink-0 text-primary" />
        )
      ) : (
        <ChevronDownIcon className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200" />
      )}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      props.forceMount
        ? 'overflow-hidden text-sm [&[data-state=closed]>div]:max-h-0 [&[data-state=open]>div]:max-h-96 [&[data-state=open]>div]:pb-4'
        : 'overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down'
    )}
    {...props}>
    <div
      className={cn(
        props.forceMount ? 'transition-all duration-300' : 'pb-4 pt-0',
        className
      )}>
      {children}
    </div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
